
$base-clr : #24BE54
$base-1: #24be54
$base-2: #e0fe4c
.headerWrapper
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    .btn--base
        margin-left: 25px
        padding: 8px 30px
        font-size: 16px 
        display: inline-block
        margin-right: 25px
        margin-top: 20px
    .logo
        margin-right: 20px
        width: 100px
        height: 100px
        transition: all ease .3s
        @media (max-width:575px)
            width: 70px
            height: 70px
        img
            width: 100%
            height: 100%
            object-fit: contain
    .menuWrapper
        flex-grow: 1
        @media screen and  (min-width: 992px)
            max-width: calc(100% - 120px)
    .menuWrapperInner
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
    .menu
        display: flex
        flex-wrap: wrap
        margin-left: auto
        width: 100%
        justify-content: flex-end
        li
            list-style: none
            @media (min-width:1400px)
                padding: 0 15px
            a
                color: #fff
                text-decoration: none
                text-transform: uppercase
                padding: 5px 10px
                font-size: 16px
                line-height: 1.5
                position: relative
                font-weight: 700
                &::before
                    content: ''
                    position: absolute
                    left: 10px
                    bottom: -2px
                    top: 100%
                    transition: .3s ease-out
                    background: linear-gradient(90deg, $base-1 0%, $base-2 100%)
                    right: 100%
                &:hover
                    color: $base-clr
                    &::before
                        right: 10px
    @media (max-width:1400px)
        .menu
            li
                a
                    padding: 5px 10px
        .socialLinks
            li
                a
                    padding: 5px 10px
        .btn--base
            margin-left: 15px
            padding: 6px 30px
    @media (max-width:991px)
        .menuWrapper
            position: fixed
            width: 100%
            height: 100vh
            z-index: 999
            left: 0
            top: 0
            background: #040404
            display: flex
            flex-wrap: nowrap
            justify-content: center
            align-items: center
            flex-direction: column
            transition: all ease .3s
            .menuWrapperInner
                padding: 20px 0 30px
                overflow-y: auto
                max-height: 100%
                width: 100%
                text-align: center
                display: block !important
            &:not(.active)
                visibility: hidden
                opacity: 0
                transform: scale(1.1)

        .menu
            margin: 0
            padding: 25px 0
            flex-wrap: nowrap
            flex-direction: column
            li 
                width: 100%
                text-align: center
                a
                    font-size: 24px
                    display: block
        .socialLinks
            justify-content: center
            margin: 0 0 25px
            li
                width: unset
                a
                    padding: 5px 10px
        .btn--base
            &:not(:hover)
                color: #040404 

header 
    width: 100%
    z-index: 999
    transition: all ease .3s
    padding: 40px 0 20px
    @media (max-width:991.98px)
        padding: 10px 0 10px
    &.topFixed
        top: 0
        padding: 5px 0
        .logo
            width: 70px
            height: 70px
    &.toSticky
        position: sticky
        padding: 15px 0 20px
        background: #080516
        animation: fadeIn .7s
        .headerWrapper .btn--base
            margin-top: 0
.header-close-button
    position: absolute        
    right: 20px
    top: 15px
    color: #ff1100
    font-size: 28px
.menuToggle
    font-size: 30px

@keyframes fadeIn
    0%
        opacity: 0
        transform: translateY(-100%)
    100%
        opacity: 1
        transform: translateY(0)