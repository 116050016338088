$base-1: #24be54;
$base-2: #e0fe4c;

.about-logo {
	margin: 0 auto 30px;

	a {
		display: block;
		max-width: 110px;
		margin: 0 auto;
	}

	img {
		width: 100%;
	}

	text-align: center;

	.title {
		margin: 0;
		margin-top: 20px;
		line-height: 1;
		font-weight: 700;
		background: -webkit-linear-gradient($base-1, $base-2);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@media screen and (min-width: 768px) {
			font-size: 70px;
		}
	}
}

.about-content {
	max-width: 720px;
	margin: 0 auto;
	font-size: 22px;
	line-height: 1.6;

	.content {
		text-align: center;
		padding-bottom: 20px;
	}

	.subtitle {
		font-size: 22px;
		line-height: 1.6;
		font-weight: 400;
	}

	@media screen and (max-width: 575px) {
		font-size: 18px;
	}
}

.contact-address {
	display: flex;

	input {
		background: transparent;
		border: 2px solid $base-1;
		color: #fff;
		padding: 0 20px;
		height: 50px;
		box-shadow: none;
		outline: none;
		font-size: 20px;
		border-radius: 5px;
		flex-grow: 1;
		width: 0;

		&::placeholder {
			color: #fff;
		}
	}

	button {
		color: #fff;
		background: #24be54;
		height: 52px;
		min-width: 52px;
		line-height: 1;
		border-radius: 5px;
		margin-left: 5px;
		box-shadow: none !important;
		outline: none !important;

		&.claim-btn {
			border: 2px solid $base-2;
			background: $base-2;
			color: #111;
			font-size: 20px;
			font-size: 24px;
			font-weight: 500;
			padding: 0 30px;
		}
	}
}

.countdown-area {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
	color: $base-1;
	justify-content: center;
}

.countdown {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	padding: 0;

	li {
		list-style: none;

		.item {
			text-align: center;
			margin: 10px;

			.title {
				font-size: 42px;
				color: $base-1;
				font-weight: 700;
				margin: 0;
				line-height: 1;
			}

			width: 100px;
			height: 100px;
			border: 2px solid $base-1;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			flex-direction: column;

			span {
				font-size: 18px;
				text-transform: capitalize;
			}
		}

		@media screen and (max-width: 575px) {
			width: 50%;

			.item {
				margin: 10px auto;
			}
		}
	}
}

body {
	color: white !important;
}