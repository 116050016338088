.footer-area {
	padding-top: 200px;
}

.footer-widget {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		justify-content: center;
		row-gap: 15px;
		column-gap: 30px;
		padding: 0;
	}

	.footer-nav {
		a {
			font-size: 20px;
			color: white;
			text-decoration: none;
		}
	}

	.footer-social {
		margin-top: 40px;
		a {
			display: flex;
			width: 55px;
			height: 55px;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: white;
			font-size: 20px;
			border-radius: 100px;
			box-shadow: 0px 0px 17px 2px #53cf4e;
			transition: all 0.3s;
			&:hover {
				background: linear-gradient(0deg, #c1f34e 0%, #2ec354 69%);
			}
		}
	}
}

.footer-newsletter {
	form {
		margin-top: 15px;
	}
	input {
		background: none;
		border-radius: 0;
		border: none;
		outline: none;
		height: 40px;
		color: white;
		padding: 10px;
		// &::placeholder{
		//     text-align: center;
		// }
	}
	button {
		background: linear-gradient(0deg, #c1f34e 0%, #2ec354 69%);
		padding: 0 25px;
		height: 40px;
		border: 0;
		color: white;
		font-size: 17px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
	}
}

.copy-right {
	padding: 80px 0 60px;
	.h6 {
		font-size: 18px;
	}
	p,
	a {
		color: #30c156;
		text-decoration: none;
	}
}

@media (max-width: 991.98px) {
	.footer-area {
		padding-top: 100px;
	}
}
